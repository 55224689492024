import React, { useRef, useEffect }  from 'react'
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
// import { getAnalytics, logEvent } from "firebase/analytics";
import * as LogSnag from '../LogSnag';

const navigation = {
  solutions: [
    { name: 'Software', href: '#' },
    { name: 'Mobile Apps', href: '#portfolio' },
    { name: 'Design', href: '#design' },
  ],
  support: [
    { name: 'Pricing', href: '#pricing' },
    { name: 'Process', href: '#process' },
    { name: 'FAQ', href: '#faq' },
  ],
  company: [
    { name: 'Book a call', href: 'https://cal.com/softlocal/30min' },
  ],
  legal: [
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [

  ],
}

const Footer = () => {
    let didAnalyticsTrigger = false;
    const elementRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
          const element = elementRef.current;
          const trigger = window.scrollY + window.innerHeight;
          const elementTop = element.offsetTop;
  
          if (trigger > elementTop) {
            // Top of the element is within the viewport
            element.classList.add('animate-fade-in-fast');
            triggerEvents()
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  
      function triggerEvents() {
        if (!didAnalyticsTrigger) {
          didAnalyticsTrigger = true
          // LogSnag
          creatLogSnagEvent()
          // Google Analytics
        //   const analytics = getAnalytics();
        //   logEvent(analytics, 'footer_visited');
  
        }
      }
  
      async function creatLogSnagEvent() {
        await LogSnag.createLogSnagEvent(LogSnag.footerViewEvent, "🫡Footer")
      }
  
  
    return (
      <div>
          <footer className="bg-gray-900" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
              Footer
            </h2>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-8">
              <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                <img
                  ref={elementRef} 
                  className="h-10"
                  src="/images/softlocal_logo.png"
                  alt="Company name"
                />
                <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                  <div className="md:grid md:grid-cols-2 md:gap-8">
  
  
                    <div>
                      <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.solutions.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
  
  
                    <div className="mt-10 md:mt-0">
                      <h3 className="text-sm font-semibold leading-6 text-white">Service</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.support.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
  
                  <div className="md:grid md:grid-cols-2 md:gap-8">
  
  
                    <div>
                      <h3 className="text-sm font-semibold leading-6 text-white">Contact</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.company.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
  
  
                    {/* <div className="mt-10 md:mt-0">
                      <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.legal.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div> */}


  
  
                  </div>
                </div>
              </div>
              <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">

              <div>
                    <dl className="mt-1 leading-7 space-y-2 text-base xs:mb-1 text-gray-600">
                      <div className="flex gap-x-4">
                        <dt className="flex-none">
                          <span className="sr-only">Telephone</span>
                          <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                        1920 McKinney Ave
                          <br />
                          Dallas, TX 75201
                        </dd>
                      </div>
                      <div className="flex gap-x-4">
                        <dt className="flex-none">
                          <span className="sr-only">Telephone</span>
                          <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                          <a className="hover:text-gray-900" href="tel:+1 (555) 234-5678">
                            +1 (510) 604-9320
                          </a>
                        </dd>
                      </div>
                      <div className="flex gap-x-4">
                        <dt className="flex-none">
                          <span className="sr-only">Telephone</span>
                          <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                          <a className="hover:text-gray-900" href="mailto:hello@example.com">
                            salvador@softlocal.io
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>


                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">Contact us now</h3>
                  <p className="mt-2 text-sm leading-6 text-gray-300">
                    Book a quick call with us to learn more about how we can help you.
                  </p>
                </div>
                <a
                href="https://cal.com/softlocal/30min"
                className="rounded-md bg-sl-green px-3.5 py-2.5 text-sm font-cartridge font-semibold text-sl-dark shadow-sm hover:bg-sl-dark hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Book a call
              </a>

              </div>
              <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                <div className="flex space-x-6 md:order-2">
                  {navigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
                <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                  &copy; 2023 SoftLocal, LLC. All rights reserved.
                </p>
              </div>
            </div>
          </footer>
      </div>
    )
}

export default Footer