import React, { useRef, useEffect } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
// import { getAnalytics, logEvent } from "firebase/analytics";
import * as LogSnag from '../LogSnag';

const pricing = {
    tiers: [
      {
        title: 'Weekly membership',
        price: 1039,
        frequency: '/week USD',
        description: 'For small projects. Get it done fast.',
        features: [
          '5 full days of work',
          'Unlimited changes',
          'Renew every week',
          'Cancel anytime',
        ],
        cta: 'Weekly billing',
        mostPopular: false,
        stripeLink: 'https://buy.stripe.com/5kA8yB6SNaiJfsIcN9',
        
        // $2,210 stripeLink: 'https://buy.stripe.com/fZe1697WRfD3a8obII',
        // $1,100 stripeLink: 'https://buy.stripe.com/28o0250upaiJ5S8aEH',
      },
      {
        title: 'Monthly. One project.',
        price: 4495,
        frequency: '/month USD',
        description: 'For long-term thinkers.',
        features: [
          'One software project at a time',
          'Unlimited changes',
          'Work items board',
          'Design, Websites, Apps',
          'Post deploy follow-ups',
          'Cancel anytime',
        ],
        cta: 'Monthly billing',
        mostPopular: true,
        stripeLink: 'https://buy.stripe.com/bIYg13fpj62t80g6oM',

        // $9,579 stripeLink: 'https://buy.stripe.com/aEUbKN4KFbmN2FW7st',
        // $4,789 stripeLink: 'https://buy.stripe.com/4gw6qtgtn62tfsI9AE',
      },
      {
        title: 'Monthly. Up to 3 projects.',
        price: 6749,
        frequency: '/month USD',
        description: 'For complex systems.',
        features: [
          '3 software projects at once', 
          'When you need an iOS app, an Android app and a web app.', 
          'Get more done',
          'Work items board', 
          'Cancel anytime'
        ],
        cta: 'Monthly billing',
        mostPopular: false,
        stripeLink: 'https://buy.stripe.com/00g1692Cx4Yp1BS8wV',

        // $19,579 stripeLink: 'https://buy.stripe.com/28o2ad3GBgH794k9AC',
        // $9,789 stripeLink: 'https://buy.stripe.com/14kbKNdhb0I980gcMR',
      },
    ],
  }

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Pricing = () => {
  const elementRef = useRef(null);
  let didAnalyticsTrigger = false;

  useEffect(() => {
      const handleScroll = () => {
        const element = elementRef.current;
        const trigger = window.scrollY + window.innerHeight;
        const elementTop = element.offsetTop;

        if (trigger > elementTop) {
          // Top of the element is within the viewport
          element.classList.add('animate-fade-in-fast');
          triggerEvents()
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    function triggerEvents() {
      if (!didAnalyticsTrigger) {
        didAnalyticsTrigger = true
        // LogSnag
        creatLogSnagEvent()
        // Google Analytics
        // const analytics = getAnalytics();
        // logEvent(analytics, 'pricing_visited');
      }
    }

    async function handleBookCallClick() {
      await LogSnag.createLogSnagEvent(LogSnag.bookCallEvent, "Book call 📲")
    }

    async function creatLogSnagEvent() {
      await LogSnag.createLogSnagEvent(LogSnag.pricingViewEvent, "pricing🤑")
    }



  return (
    <div>
        <div id="pricing" className="mx-auto max-w-7xl bg-white py-8 px-6 lg:px-8">
            <h2 ref={elementRef} className="text-3xl flex justify-center font-cartridge font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
                Pricing plans for every project size
            </h2>
            <p className="mx-auto mt-6 max-w-2xl text-xl text-gray-500">
                Choose a plan that best accomodates your needs. Whether it's a quick mockup or an entire software system, we can get it done.
            </p>

            {/* Tiers */}
            <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
                {pricing.tiers.map((tier) => (
                <div
                    key={tier.title}
                    className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
                >
                    <div className="flex-1">
                    <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
                    {tier.mostPopular ? (
                        <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-sl-green py-1.5 px-4 text-sm font-semibold text-sl-dark">
                        Most popular
                        </p>
                    ) : null}
                    <p className="mt-4 flex justify-center items-baseline text-gray-900">
                        <span className="text-5xl font-bold tracking-tight">${tier.price}</span>
                        <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
                    </p>
                    <p className="mt-6 text-gray-500">{tier.description}</p>

                    {/* Feature list */}
                    <ul role="list" className="mt-6 space-y-6">
                        {tier.features.map((feature) => (
                        <li key={feature} className="flex">
                            <CheckIcon className="h-6 w-6 flex-shrink-0 text-sl-dark" aria-hidden="true" />
                            <span className="ml-3 text-gray-500">{feature}</span>
                        </li>
                        ))}
                    </ul>
                    </div>

                    <a
                    href={tier.stripeLink}
                    className={classNames(
                        tier.mostPopular
                        ? 'bg-sl-dark text-white hover:bg-sl-green hover:text-sl-dark'
                        : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100',
                        'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
                    )}
                    >
                    {tier.cta}
                    </a>
                    <a className="underline flex justify-center mt-8 font-semibold text-sl-dark" onClick={handleBookCallClick} href="https://cal.com/softlocal/30min" target="_blank">
                      Book a video call
                    </a>

                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Pricing